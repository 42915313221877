var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewDesign',[_c('template',{slot:"toolBarTitle"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chart-bell-curve-cumulative")]),_c('span',[_vm._v(_vm._s(_vm.$t('trends.heading')))])],1),_c('template',{slot:"toolBarButtons"},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.isTouchDevice()},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.exportToPdf}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download-circle-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(this.$t('general.download')))])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.isTouchDevice()},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.showDateRangeCompareSelection}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isCompareChart ? 'primary' : undefined}},[_vm._v("mdi-chart-multiple")])],1)]}}])},[_c('span',[_vm._v(_vm._s(this.$t('trends.dateRangeCompareDialog.title')))])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.isTouchDevice()},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.showDateRangeSelection}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":!_vm.isCompareChart ? 'primary' : undefined}},[_vm._v("mdi-calendar-range-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(this.$t('trends.dateRangeDialog.title')))])])],1),[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.loading)?_c('v-fade-transition',[_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1):_c('highcharts',{ref:"chart",attrs:{"options":_vm.chartOptions}})],1),_c('dateRangeDialog',{ref:"dateRangeDialog",on:{"saveItem":_vm.saveDateRange}}),_c('dateRangeCompareDialog',{ref:"dateRangeCompareDialog",on:{"saveItem":_vm.saveDateRangeCompare}}),_c('series-dialog',{ref:"seriesDialog",on:{"showMoodItem":_vm.showMoodItem}}),_c('moodaryDialog',{ref:"moodDialog",on:{"saveItem":_vm.saveItem,"deleteItem":_vm.deleteItem}}),_c('persistent-dialog',{ref:"confirmDialog"})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }