























































import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import dateRangeDialog from "@/components/trends/dialog/DateRangeDialog.vue";
import DateRangeDialog from "@/components/trends/dialog/DateRangeDialog.vue";
import dateRangeCompareDialog from "@/components/trends/dialog/DateRangeCompareDialog.vue";
import DateRangeCompareDialog from "@/components/trends/dialog/DateRangeCompareDialog.vue";
import DateUtility from "@/assets/utils/DateFormatUtil"
import persistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import seriesDialog from "@/components/trends/dialog/SeriesDialog.vue";
import moodaryDialog from "@/components/moodary/dialog/MoodaryDialog.vue";
import DateRangeCompare from "@/models/DateRangeCompare";
import DateRangeCompareResponse from "@/models/DateRangeCompareResponse";
import RangeCompareItem from "@/models/RangeCompareItem";
import moment from "moment-mini";
import {globalEventBus} from "@/main";
import ViewDesign from "@/components/global/ViewDesign.vue";
import {MoodType} from "@/models/MoodType";
import UserSettings from "@/models/UserSettings";
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import SeriesDialog from "@/components/trends/dialog/SeriesDialog.vue";
import MoodaryDialog from "@/components/moodary/dialog/MoodaryDialog.vue";

@Component({
    components: {
        dateRangeDialog, dateRangeCompareDialog, persistentDialog, seriesDialog, moodaryDialog, ViewDesign
    }
})
export default class Trends extends Vue {
    private isCompareChart: boolean = false;
    private showDarkIcons: boolean = false;
    private dates !: string[];
    private chartOptions: any = {
        chart: {
            backgroundColor: 'transparent',
        },
        title: {
            text: '',
            align: "left"
        },
        subtitle: {
            text: '',
            align: "left"
        },
        exporting: {
            enabled: false,
            allowHTML: true,
            chartOptions: {},
            showTable: true
        },
        yAxis: {
            labels: {
                useHTML: true,
                formatter: this.labelFormatter
            },
            title: {
                text: this.$t('general.mood')
            },
            min: 1,
            max: 5
        },
    };
    private labelsWeek!: Date[];
    private valueWeek!: number[];
    private moodEntries: MoodItem[] = [];
    private loading: boolean = true;
    @Ref()
    private seriesDialog!:SeriesDialog;
    @Ref()
    private confirmDialog!: PersistentDialog;
    private get dateRangeCompare(): DateRangeCompare {

        return this.$store.getters.dateRangeCompare;
    }

    private set dateRangeCompare(dateRangeCompare: DateRangeCompare) {
        this.$store.commit('SET_DATERANGECOMPARE', dateRangeCompare)
    }
@Ref()
private moodDialog!: MoodaryDialog;
    @Ref()
    private dateRangeCompareDialog!: DateRangeCompareDialog;

    @Ref()
    private dateRangeDialog!: DateRangeDialog;

    constructor() {
        super();
    }

    async mounted() {
        globalEventBus.$on("darkModeChanged", this.refreshTrend);
        globalEventBus.$on('swipeLeft',this.showDateRangeSelection);
        globalEventBus.$on("userSettingsChanged", this.userSettingsChanged);
        await this.refreshTrend();
    }
    private async userSettingsChanged(settings:UserSettings) {
        await this.refreshTrend();
    }
    private getDateRange(compareItem: RangeCompareItem): string[] {
        switch (compareItem.type) {
            //day
            case 0:
                return [compareItem.date, compareItem.date];
            // week
            case 1:
                return DateUtility.getCurrentWeekDates(new Date(compareItem.date));
            // month
            case 2:
                return DateUtility.getCurrentMonthDates(new Date(compareItem.date));
        }
        return [];
    }
    private deleteItem(args:{item:MoodItem}) {
        this.confirmDialog.showConfirmation(this.$tc('moodary.confirmation.doDeleteTitle'), this.$tc('moodary.confirmation.doDeleteText'), () => {
            this.$webApi.deleteMoodEntry(args.item).then((success) => {
                if (success) {
                    this.refreshTrend();
                }
            })
        });
    }
    private getDateRangeCompareItems(compareItem: RangeCompareItem): Promise<DateRangeCompareResponse> {
        let returnValue;
        try {
            if (compareItem) {
                const dateRange = this.getDateRange(compareItem);
                returnValue = this.$webApi.readMoodEntriesRange(dateRange[0], dateRange[1], undefined).then((result => {
                    const compareItemResult = new DateRangeCompareResponse();
                    compareItemResult.request = compareItem;
                    if (result?.data) {
                        compareItemResult.response = result.data;
                    }
                    return compareItemResult;
                }));
            }
        } catch {
            return new Promise<DateRangeCompareResponse>(() => {
                return new DateRangeCompareResponse();
            });
        } finally {
            this.loading = false;
        }
        return returnValue;
    }

    private async loadDateRangeCompareData(initial: boolean = false): Promise<DateRangeCompareResponse[]> {
        this.loading = true;
        const compareResult: DateRangeCompareResponse[] = [];
        const allRequests: Promise<DateRangeCompareResponse>[] = [];
        this.dateRangeCompare.items.forEach((item) => {
            allRequests.push(this.getDateRangeCompareItems(item));
        });
        await Promise.all(allRequests).then((result) => {
            result.forEach((response) => {
                compareResult.push(response);
            });
        }).catch((e) => {
            compareResult.push(e)
        }).finally(() => {
            this.loading = false;
        });
        return compareResult;
    }

    private async loadData(initial: boolean = false) {
        this.loading = true;
        if (initial) {
            this.setStoreDates(this.$store.getters.userSelection);
            const storeDates = this.$store.getters.dateRange;
            if (storeDates && storeDates.length > 0) {
                this.dates = storeDates;
            } else {
                this.dates = DateUtility.getCurrentWeekDates();
            }
        }
        await this.$webApi.readMoodEntriesRange(this.dates[0], this.dates[1], undefined).then((result => {
            if (result?.data) {
                this.moodEntries = result.data;
                this.customSort(result.data, true);
            }
        })).catch((e) => {
            this.moodEntries = [];

        }).finally(() => {
            this.refreshChart();
            this.darkmodeChanges();
            this.loading = false;
        });
    }

    private get formattedDateRange() {
        const dateRangeSelection = this.$store.getters.userSelection;
        switch (dateRangeSelection) {
            case 0:
                return this.$t('trends.dateRangeDialog.buttons.thisWeek');
            case 1:
                return this.$t('trends.dateRangeDialog.buttons.lastWeek');
            case 2:
                return this.$t('trends.dateRangeDialog.buttons.thisMonth');
            case 3:
                return this.$t('trends.dateRangeDialog.buttons.lastMonth');
            default:
                return this.$t('trends.dateRangeDialog.buttons.customRange');
        }
    };

    @Watch('dates')
    private dateRangeChange(): void {
        //this.formattedDateRange = this.$vuetify.breakpoint.smAndDown?DateUtility.getFormattedDateRange(this.dates,'<br />'):DateUtility.getFormattedDateRange(this.dates,'-');
    }

    @Watch('$store.state.darkmode')
    private darkmodeChanges() {
        this.showDarkIcons = !this.$store.getters.darkmode;
        this.chartOptions = this.getChartOptions();
    }

    private async showMoodItem(item: MoodItem): Promise<void> {
        await this.moodDialog.editItem(item);
    }

    private filterMoodEntries(item: MoodItem): boolean {
        if (this.dates && this.dates[0] && this.dates[1]) {
            return item.date >= this.dates[0] && item.date <= this.dates[1];
        }
        return false;
    }

    private getTypeText(type: number): string {
        switch (type) {
            case 0:
                return this.$tc('trends.dateRangeCompareDialog.types.day');
            case 1:
                return this.$tc('trends.dateRangeCompareDialog.types.week');
            default:
                return this.$tc('trends.dateRangeCompareDialog.types.month');
        }
    }

    private async getCompareChartOptions(items: DateRangeCompareResponse[], type: number): Promise<any> {
        const compareData = this.buildCompareData(items, type);
        return {
            exporting: {
                enabled: false,
                allowHTML: true,
                chartOptions: {},
                showTable: true
            },
            credits: {
                enabled: true,
                text: "moodary.com",
                href: "",
                style: {
                    cursor: ""
                }
            },
            title: {
                text: this.$t('trends.dateRangeCompareDialog.typeLabel') + " (" + this.getTypeText(type) + ")",
                align: "left"
            },
            subtitle: {
                text: " ",
                align: "left"
            },
            chart: {
                type: "line",
                backgroundColor: {
                    linearGradient: [250, 250, 250, 500],
                    stops: [
                        [0, 'rgba(128, 128, 128,0)']
                    ]
                },
                style: {
                    fontFamily: "Roboto, sans-serif"
                },
                borderWidth: 0,
                plotBackgroundColor: 'rgba(128, 128, 128, 0)',
            },
            xAxis: {
                labels: {
                    useHTML: false,
                    formatter: undefined
                },
                type: "string",
                categories: this.getLabels(type),
                title: {
                    text: this.getPeriodText(type)
                }
            },
            yAxis: {
                labels: {
                    useHTML: true,
                    formatter: this.labelFormatter
                },
                title: {
                    text: this.$t('general.mood')
                },
                min: 1,
                max: 5
            },
            tooltip: {
                enabled: false
            },
            series: await this.getSeries(compareData, items, type)
        };
    }

    private getChartOptions(): any {
        return {
            exporting: {
                enabled: false,
                allowHTML: true,
                chartOptions: {},
                showTable: true
            },
            credits: {
                enabled: true,
                text: "moodary.com",
                href: "",
                style: {
                    cursor: ""
                }
            },
            title: {
                text: this.formattedDateRange,
                align: "left"
            },
            subtitle: {
                text: DateUtility.getFormattedDateRange(this.dates, '-'),
                align: "left"
            },
            chart: {
                //type: "spline",
                backgroundColor: {
                    linearGradient: [250, 250, 250, 500],
                    stops: [
                        [0, 'rgba(128, 128, 128,0)']
                    ]
                },
                style: {
                    fontFamily: "Roboto, sans-serif"
                },
                borderWidth: 0,
                plotBackgroundColor: 'rgba(128, 128, 128, 0)',
            },
            xAxis: {
                labels: {
                    useHTML: false,
                    formatter: this.dateLabelFormatter
                },
                type: "string",
                categories: this.getLabelsWeek(this.moodEntries),
                title: {
                    text: this.$t('general.date')
                }
            },
            yAxis: {
                labels: {
                    useHTML: true,
                    formatter: this.labelFormatter
                },
                title: {
                    text: this.$t('general.mood')
                },
                min: 1,
                max: 5
            },
            tooltip: {
                enabled: false
            },
            series: [{
                type: 'spline',
                data: this.getValuesWeek(this.moodEntries),
                color: '#33cc33',
                showInLegend: false,
                name: "Laune 1",
                cursor: 'pointer',
                events: {
                    click: this.clickSeries
                }
            }]
        };
    }

    private clickCompareSeries(event): void {

    }

    private clickSeries(event): void {
        //const persistentDialog = this.seriesDialog;
        console.log(event);
        this.seriesDialog.showDialog(event.point.category);
    }

    private getLabelsWeek(items: MoodItem[]): Date[] {
        if (!this.labelsWeek) {
            const result = this.buildData(items);
            this.labelsWeek = result.labels;
            this.valueWeek = result.values;
        }
        return this.labelsWeek;
    }

    private getValuesWeek(items: MoodItem[]): number[] {
        if (!this.valueWeek) {
            const result = this.buildData(items);
            this.labelsWeek = result.labels;
            this.valueWeek = result.values;
        }

        return this.valueWeek;
    }

    private buildCompareTypeData(items: MoodItem[], type: number | undefined = undefined): { labels, values } {
        let labels = new Array<number>();
        let values = new Array<number>();

        function getDateTime(item: MoodItem): Date {
            if (item.date) {
                if (item.time) {
                    return new Date(item.date + " " + item.time);
                } else {
                    return new Date(item.date);
                }
            }
            return new Date();
        }

        function getKey(item: MoodItem, type: number): number {
            const dateValue = getDateTime(item);
            let key = -1;
            switch (type) {
                case 0:
                    key = dateValue.getHours();
                    break;
                case 1:
                    key = dateValue.getDay();
                    break;
                case 2:
                    key = dateValue.getDate();
                    break;
            }
            if (key === 0) {
                key = 7;
            }

            return key;
        }

        if (items) {
            // group the data
            let groupedData = items.reduce(function (l, r) {
                // construct a unique key out of the properties we want to group by
                let key = getKey(r, type || 0);
                // check if the key is already known
                if (typeof l[key] === "undefined") {
                    // init with an "empty" object
                    l[key] = {
                        sum: 0,
                        count: 0
                    };
                }
                // sum up the values and count the occurences
                l[key].sum += r.moodValue;
                l[key].count += 1;
                return l;
            }, {});

            let avgGroupedData = Object.keys(groupedData).map(function (key) {
                return {
                    key: key,
                    value: (groupedData[key].sum / groupedData[key].count)
                };
            });

            avgGroupedData.forEach((d) => {
                labels.push(Number.parseInt(d.key));
                values.push(d.value);
            });
        }
        return {labels: labels, values: values};
    }

    private buildData(items: MoodItem[], type: number | undefined = undefined): { labels, values } {
        let labels = new Array<Date>();
        let values = new Array<number>();

        function getKey(date: string, type: number | undefined) {
            const dateValue = new Date(date);
            if (type) {
                switch (type) {
                    case 0:
                        return dateValue.getHours();
                    case 1:
                        return dateValue.getDay();
                    case 2:
                        return dateValue.getMonth();
                }
            }
            return date;
        }

        // group the data
        let groupedData = items.reduce(function (l, r) {
            // construct a unique key out of the properties we want to group by
            let key = getKey(r.date, type);

            // check if the key is already known
            if (typeof l[key] === "undefined") {
                // init with an "empty" object
                l[key] = {
                    sum: 0,
                    count: 0
                };
            }
            // sum up the values and count the occurences
            l[key].sum += r.moodValue;
            l[key].count += 1;
            return l;
        }, {});

        let avgGroupedData = Object.keys(groupedData).map(function (key) {
            return {
                date: new Date(key),
                value: (groupedData[key].sum / groupedData[key].count)
            };
        });

        avgGroupedData.sort((a, b) => {
            return a.date.getTime() - b.date.getTime()
        });

        avgGroupedData.forEach((d) => {
            labels.push(d.date);
            values.push(d.value);
        });

        return {labels: labels, values: values};
    }

    private async showDateRangeCompareSelection(): Promise<void> {
        this.dateRangeCompareDialog.showDialog(this.dateRangeCompare);
    }

    private showDateRangeSelection(): void {
        this.dateRangeDialog.showDialog(this.dates);
    }

    private customSort(items: MoodItem[], isDesc: boolean): MoodItem[] {
        items.sort((a, b) => {
            if (!isDesc) {
                return Date.parse(b.date) - Date.parse(a.date);
            } else {
                return Date.parse(a.date) - Date.parse(b.date);
            }
        });
        return items;
    }

    private exportToPdf(): void {
        const darkIcons = this.showDarkIcons;
        this.showDarkIcons = true;
        // @ts-ignore
        (<HighchartsVue>this.$refs.chart).chart.exportChart({
            type: 'application/pdf',
            allowHTML: true,
            filename: this.getFilename(),
        });
        this.showDarkIcons = darkIcons;
    }

    private dateLabelFormatter(label: any): string {
        return this.$d(label.value, "date");
    }

    private labelFormatter(label: any): string {
        const diarySettings = this.$store.getters.diarySettings;

        if(diarySettings.moodType === MoodType.Mood5) {
            switch (label.value) {
                case 5:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='excited' width='24' height='24' src='https://moodary.com/img/moods/excited-light.svg' />" :
                        "<img style='border: 0;' alt='excited' width='24' height='24' src='https://moodary.com/img/moods/excited-dark.svg' />";
                case 4:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='happy' width='24' height='24' src='https://moodary.com/img/moods/happy-light.svg' />" :
                        "<img style='border: 0;' alt='happy' width='24' height='24' src='https://moodary.com/img/moods/happy-dark.svg' />";
                case 3:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='neutral' width='24' height='24' src='https://moodary.com/img/moods/neutral-light.svg' />" :
                        "<img style='border: 0;' alt='neutral' width='24' height='24' src='https://moodary.com/img/moods/neutral-dark.svg' />";
                case 2:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='sad' width='24' height='24' src='https://moodary.com/img/moods/sad-light.svg' />" :
                        "<img style='border: 0;' alt='sad' width='24' height='24' src='https://moodary.com/img/moods/sad-dark.svg' />";
                default:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='cry' width='24' height='24' src='https://moodary.com/img/moods/cry-light.svg' />" :
                        "<img style='border: 0;' alt='cry' width='24' height='24' src='https://moodary.com/img/moods/cry-dark.svg' />";
            }
        }else if(diarySettings.moodType === MoodType.Mood3) {
            switch (label.value) {
                case 4:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='happy' width='24' height='24' src='https://moodary.com/img/moods/happy-light.svg' />" :
                        "<img style='border: 0;' alt='happy' width='24' height='24' src='https://moodary.com/img/moods/happy-dark.svg' />";
                case 3:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='neutral' width='24' height='24' src='https://moodary.com/img/moods/neutral-light.svg' />" :
                        "<img style='border: 0;' alt='neutral' width='24' height='24' src='https://moodary.com/img/moods/neutral-dark.svg' />";
                case 2:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='sad' width='24' height='24' src='https://moodary.com/img/moods/sad-light.svg' />" :
                        "<img style='border: 0;' alt='sad' width='24' height='24' src='https://moodary.com/img/moods/sad-dark.svg' />";
                 default: return '';
            }
        } else {
            switch (label.value) {

                case 4:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='thumb-up' width='24' height='24' src='https://moodary.com/img/moods/thumb-up-light.svg' />" :
                        "<img style='border: 0;' alt='thumb-up' width='24' height='24' src='https://moodary.com/img/moods/thumb-up-dark.svg' />";
                case 2:
                    return this.showDarkIcons ?
                        "<img style='border: 0;' alt='thumb-down' width='24' height='24' src='https://moodary.com/img/moods/thumb-down-light.svg' />" :
                        "<img style='border: 0;' alt='thumb-down' width='24' height='24' src='https://moodary.com/img/moods/thumb-down-dark.svg' />";
                default:
                    return '';
            }
        }

    }

    private getFilename(): string {
        if (this.isCompareChart) {
            return this.$tc('trends.dateRangeDialog.title') + '_' + DateUtility.getDateString(new Date(), "");
        }
        let filename = this.formattedDateRange + "_";
        filename += DateUtility.getDateString(new Date(this.dates[0]), "") + "-";
        filename += DateUtility.getDateString(new Date(this.dates[1]), "");
        return filename;
    }

    private saveDateRange(args: { dateRange: string[], userSelection: number }): void {
        this.dates = args.dateRange;
        this.$store.commit("SET_USERSELECTION", args.userSelection);
        this.$store.commit("SET_DATERANGE", this.dates);
        this.$store.commit("SET_RANGETYPE", 0)
        this.loadData();
    }

    private async saveDateRangeCompare(args: DateRangeCompare): Promise<void> {

        this.$store.commit('SET_RANGETYPE', 1);
        this.$store.commit('SET_DATERANGECOMPARE', args);
        this.isCompareChart = true;

        const rangeCompareData = await this.loadDateRangeCompareData(true);
        this.chartOptions = await this.getCompareChartOptions(rangeCompareData, args.type);
    }

    private refreshChart(): void {
        this.isCompareChart = false;
        const result = this.buildData(this.moodEntries);
        this.labelsWeek = result.labels;
        this.valueWeek = result.values;
        this.chartOptions = this.getChartOptions();
    }

    private saveItem(args: { item: MoodItem, isNew: boolean }): void {
        this.$webApi.updateMoodEntry(args.item).then((response) => {
            if (response) {
                this.loadData();
            }
        });
    }

    private buildCompareData(items: DateRangeCompareResponse[], type: number): { labels, values }[] {
        const result: { labels, values }[] = [];
        items.forEach((item) => {
            result.push(this.buildCompareTypeData(item.response, type));
        });
        return result;
    }

    private getSeriesTitle(dateString: string, type: number): string {
        switch (type) {
            case 1:
                const date = moment(dateString);
                return this.$t('trends.dateRangeCompareDialog.calendarWeek', {
                    week: date.isoWeek(),
                    year: date.isoWeekYear()
                }).toString();
            case 2:
                return this.$d(new Date(dateString), 'month');
        }
        return dateString;

    }

    private getColor(index: number, request: RangeCompareItem): string | undefined {
        if (request.color) {
            if (request.color.length > 7 && !request.color.endsWith('00')) {
                return request.color.substr(0, 7);
            }
        }
        // if(index<7) {
        //     const colors =
        //         ['#FF0000',
        //         '#00FF00',
        //         '#0000FF',
        //         '#DFFF00',
        //         '#FFBF00',
        //         '#DE3163',
        //         '#6495ED'];
        //     return colors[index];
        // }

        return undefined;
    }

    private async getSeries(series: { labels; values }[], items: DateRangeCompareResponse[], type: number): Promise<any[]> {
        const result: any[] = [];
        let counter = 0;
        series.forEach((serie) => {
            const values = this.fillValues(serie, type);
            const request = items[counter].request;
            const dateString = request.date;
            let title = request.title;
            if (!title) {
                title = this.getSeriesTitle(dateString, type);
            }
            const color = this.getColor(counter++, request);
            result.push(
                {

                    type: 'spline',
                    data: values,
                    color: color,
                    showInLegend: true,
                    name: title,
                    cursor: 'pointer',

                    events: {
                        click: this.clickCompareSeries
                    }
                }
            )
        });
        return result;
    }

    private getPeriodText(type: number): string {
        switch (type) {
            case 0:
                return this.$tc("trends.period.dayTime");
            case 1:
                return this.$tc("trends.period.weekDay");
            default:
                return this.$tc("trends.period.day");
        }
    }

    private getLabels(type: number): string[] {
        switch (type) {
            case 0:
                return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
            case 1:
                const fdow = parseInt(this.$tc("general.firstDayOfWeek"));
                if (fdow === 0) {
                    return [this.$tc('general.weekdays.sun'), this.$tc('general.weekdays.mon'), this.$tc('general.weekdays.tue'), this.$tc('general.weekdays.wed'), this.$tc('general.weekdays.thr'), this.$tc('general.weekdays.fri'), this.$tc('general.weekdays.sat')];
                }
                return [this.$tc('general.weekdays.mon'), this.$tc('general.weekdays.tue'), this.$tc('general.weekdays.wed'), this.$tc('general.weekdays.thr'), this.$tc('general.weekdays.fri'), this.$tc('general.weekdays.sat'), this.$tc('general.weekdays.sun')];
            default:
                return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
        }
    }

    private fillValues(serie: { labels: number[]; values: number[] }, type): number[] {
        const result: number[] = [];
        const fdow = parseInt(this.$tc("general.firstDayOfWeek"));
        switch (type) {
            case 0:
                for (let i = 0; i < 24; i++) {
                    const index = serie.labels.findIndex((label) => label === (i + 1));
                    let dayValue = 1;
                    if (index > -1) {
                        dayValue = serie.values[index];
                    } else {
                        if (i > 0) {
                            dayValue = result[i - 1];
                        }
                    }
                    result.push(dayValue);
                }
                return result;
            case 1:
                for (let i = 0; i < 7; i++) {
                    const index = serie.labels.findIndex((label) => label === (i + 1));
                    let dayValue = 1;
                    if (index === -1) {
                        // last value
                        if (i > 0) {
                            dayValue = result[i - 1];
                        }
                    } else {
                        dayValue = serie.values[index];
                    }
                    result.push(dayValue);
                }
                if (fdow === 0) {
                    const newResult = [result[6], result[0], result[1], result[2], result[3], result[4], result[5]]
                    return newResult;
                }
                return result;
            default:
                for (let i = 0; i < 31; i++) {
                    const index = serie.labels.findIndex((label) => label === (i + 1));
                    let dayValue = 1;
                    if (index > -1) {
                        dayValue = serie.values[index];
                    } else {
                        if (i > 0) {
                            dayValue = result[i - 1];
                        }
                    }
                    result.push(dayValue);
                }
                return result;
        }
    }

    private setStoreDates(userSelection: number) {
        if (userSelection < 4) {
            switch (userSelection) {
                case 0:
                    this.dates = DateUtility.getCurrentWeekDates();
                    break;
                case 1:
                    this.dates = DateUtility.getLastWeekDates();
                    break;
                case 2:
                    this.dates = DateUtility.getCurrentMonthDates();
                    break;
                case 3:
                    this.dates = DateUtility.getLastMonthDates();
                    break;
            }
        }
        this.$store.commit('SET_DATERANGE', this.dates);
    }

    private async refreshTrend(): Promise<void> {
        try {
            this.showDarkIcons = !this.$store.getters.darkmode;
            this.isCompareChart = this.$store.getters.rangeType > 0;
            this.setStoreDates(this.$store.getters.userSelection);
            if (this.isCompareChart) {
                await this.saveDateRangeCompare(this.dateRangeCompare);
            } else {
                await this.loadData(true);
            }
        } catch {
        } finally {
            this.loading = false;
        }
    }

    destroyed() {
        globalEventBus.$off("darkModeChanged", this.refreshTrend);
        globalEventBus.$off('swipeLeft',this.showDateRangeSelection);
        globalEventBus.$off("userSettingsChanged", this.userSettingsChanged);
    }
}

