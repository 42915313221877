import DateUtility from "@/assets/utils/DateFormatUtil";

export default class RangeCompareItem {
    public type!: number;
    public color !: string;
    public date: string = '';
    public title: string = '';

    constructor(type: number = 0) {
        this.type = type;
        this.date = DateUtility.getDateString(new Date());
    }
}

