




































import {Component, Vue} from 'vue-property-decorator';
import DateRangeSelect from "@/components/trends/DateRangeSelect.vue";
import DateRangeSelection from "@/models/DateRangeSelection";

@Component({
    components: {DateRangeSelect}
})
export default class DateRangeDialog extends Vue {

    private openDialog: boolean = false;

    private activeRangeSelection: DateRangeSelection = new DateRangeSelection();

    constructor() {
        super();
    }

    public showDialog(dateRange: string[]): void {
        this.activeRangeSelection.activeRangeSelection = this.$store.getters.userSelection;
        this.activeRangeSelection.dateRange = dateRange;
        this.openDialog = true;
    }

    private closeDialog(): void {
        this.openDialog = false;
    }

    private saveItem(): void {
        this.$emit("saveItem", {
            dateRange: this.activeRangeSelection.dateRange,
            userSelection: this.activeRangeSelection.activeRangeSelection
        });
        this.closeDialog();
    }
}
