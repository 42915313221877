
















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DatePicker from "@/components/global/DatePicker.vue";
import ColorPicker from "@/components/global/ColorPicker.vue";
import RangeCompareItem from "@/models/RangeCompareItem";

@Component({components: {ColorPicker, DatePicker}})
export default class CardList extends Vue {

    @Prop({required: true})
    public readonly value!: RangeCompareItem[];


    private valueCopy: RangeCompareItem[] = [];

    @Watch("value")
    openOnChange() {
        this.valueCopy = this.value;
    }

    constructor() {
        super();
    }

    private remove(index) {
        this.valueCopy = [...this.valueCopy.slice(0, index), ...this.valueCopy.slice(index + 1)];
        this.$emit('input', this.valueCopy);
    }

    private up(index) {
        const newValue = [...this.valueCopy];
        newValue[index] = this.valueCopy[index - 1];
        newValue[index - 1] = this.valueCopy[index];
        this.valueCopy = newValue;
        this.$emit('input', this.valueCopy);
    }

    private down(index) {
        const newValue = [...this.valueCopy];
        newValue[index] = this.valueCopy[index + 1];
        newValue[index + 1] = this.valueCopy[index];
        this.valueCopy = newValue;
        this.$emit('input', this.valueCopy);
    }

    mounted() {
        this.openOnChange();
    }
}
