























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import DateUtility from "@/assets/utils/DateFormatUtil";
import DateRangeSelection from "@/models/DateRangeSelection";


@Component({
    components: {},
})
export default class DateRangeSelect extends Vue {
    @Prop({required: true})
    private value!: DateRangeSelection;

    @Watch("value.activeRangeSelection")
    changeActiveRangeValue() {
        this.isReadOnlyDatePicker = this.value.activeRangeSelection !== 4;
        switch (this.value.activeRangeSelection) {
            case 0:
                this.value.dateRange = DateUtility.getCurrentWeekDates();
                break;
            case 1:
                this.value.dateRange = DateUtility.getLastWeekDates();
                break;
            case 2:
                this.value.dateRange = DateUtility.getCurrentMonthDates();
                break;
            case 3:
                this.value.dateRange = DateUtility.getLastMonthDates();
                break;
            case 4 :
                this.pickerDate = '';
                break;
        }
        this.pickerDate = this.value.dateRange[0];

        if (this.value.activeRangeSelection === 4) {
            this.pickerDate = '';
        }

        this.$emit("input", this.value);
    }

    @Watch("value.dateRange")
    private changeDateRangeValue() {

        if (this.value.dateRange && this.value.dateRange[0] && this.value.dateRange[1]) {
            if (this.value.dateRange[0] > this.value.dateRange[1]) {
                this.value.dateRange = [this.value.dateRange[1], this.value.dateRange[0]];
            }
        }
        this.$emit("input", this.value);
    }

    private isReadOnlyDatePicker: boolean = true;
    private pickerDate: string = '';

    private readonly rangeSelectionItems: { key: number, value: any }[] = [
        {key: 0, value: this.$t('trends.dateRangeDialog.buttons.thisWeek')},
        {key: 1, value: this.$t('trends.dateRangeDialog.buttons.lastWeek')},
        {key: 2, value: this.$t('trends.dateRangeDialog.buttons.thisMonth')},
        {key: 3, value: this.$t('trends.dateRangeDialog.buttons.lastMonth')},
        {key: 4, value: this.$t('trends.dateRangeDialog.buttons.customRange')}
    ];

    private formattedDateRange(): string {
        return DateUtility.getFormattedDateRange(this.value.dateRange, '-');
    }

    private get maxDate(): string {
        return MoodItem.getFormattedDate(new Date());
    }

}
