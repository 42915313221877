




































































import {Component, Vue} from 'vue-property-decorator';
import DateRangeCompare from "@/models/DateRangeCompare";
import CardList from "@/components/global/CardList.vue";
import RangeCompareItem from "@/models/RangeCompareItem";

@Component({
    components: {CardList}
})
export default class DateRangeCompareDialog extends Vue {

    private openDialog: boolean = false;
    private type: number = 0;
    private items: RangeCompareItem[] = [];


    private readonly rangeTypeItems: { key: number, value: any }[] = [
        {key: 0, value: this.$t('trends.dateRangeCompareDialog.types.day')},
        {key: 1, value: this.$t('trends.dateRangeCompareDialog.types.week')},
        {key: 2, value: this.$t('trends.dateRangeCompareDialog.types.month')},
    ];

    constructor() {
        super();
    }

    private add() {
        this.items.push(new RangeCompareItem(this.type));
    }

    public showDialog(dateRangeCompare: DateRangeCompare): void {
        this.type = JSON.parse(JSON.stringify(dateRangeCompare.type));
        this.items = JSON.parse(JSON.stringify(dateRangeCompare.items));
        this.openDialog = true;
    }

    private closeDialog(): void {
        this.openDialog = false;
    }


    private dateRangeCompareTypeChanged() {
        this.items = [];
    }

    private saveItem(): void {
        this.$emit("saveItem", {type: this.type, items: this.items});
        this.closeDialog();
    }

}
